/* vimeo lazyload container */
.vi-lazyload {
  width: 100%;
  height: 110%;
  position: absolute !important;
  z-index: -1;
  overflow: hidden;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* vimeo lazyload container ::before --> to maintain 16/9 ratio & black bg before JS loaded */
.vi-lazyload::before {
  content: "";
  width: 100%;
  display: block;
  position: relative;
  padding-top: 56.25%;
  /*16:9 ratio*/
  background-color: transparent;
}

/* vimeo lazyload wrap */
.vi-lazyload-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: inherit;
}

/* vimeo lazyload content */
.vi-lazyload-content {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: transparent;
  background-image: var(--vi-lazyload-img);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

/* 0 = no logo! */
/* 1 = blue  - #1ab7ea */
.vi-lazyload[data-logo="1"] .vi-lazyload-logo {
}

/* 2 = dark  - #162221 */
.vi-lazyload[data-logo="2"] .vi-lazyload-logo {
  filter: brightness(0) saturate(100%) invert(10%) sepia(31%) saturate(433%)
    hue-rotate(126deg) brightness(96%) contrast(95%);
}

/* 3 = white - #fff    */
.vi-lazyload[data-logo="3"] .vi-lazyload-logo {
  filter: grayscale(100%) brightness(200%);
}

/* iframe */
.vi-lazyload iframe {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  border: 0;
  background-color: #000;
}
